
import { Check, Close } from '@element-plus/icons-vue'
import { computed, defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import axios from '@/http'
import { IOrder, IOrderDelivery } from '@/interfaces'
import { enumDeliveryTypes, localizeDeliveryTypes } from '@/constains'

interface IMovingBoxesOrder extends IOrder {
	ordersDeliveries: [IOrderDelivery]
	boxes: Array<{ num: number; active: boolean }>
}

export default defineComponent({
	name: 'MovingOrderBoxes',

	components: {
		Check,
		Close
	},

	props: {
		propOrderId: {
			type: Number
		}
	},

	emits: ['success'],

	setup(props, ctx) {
		const modelInput = ref<string | null>(null)
		const mapOrderBoxes = ref<{ [key: string]: number[] }>({})
		const orders = ref<Array<IMovingBoxesOrder>>([])

		const isFetchingGetOrder = ref(false)
		const isFetchingSend = ref(false)

		const orderWithBoxes = computed(() => {
			return orders.value.map(el => {
				el.boxes = []

				for (let i = 1; i <= el.ordersDeliveries[0].boxes; i++) {
					el.boxes.push({ num: i, active: mapOrderBoxes.value[el.id]?.includes(i) })
				}

				return el
			})
		})

		const isConfirmMoving = computed(
			() =>
				orders.value?.length &&
				orders.value.every(el => el.boxes.every(item => item.active)) &&
				orders.value.every(el => el.state === orders.value[0].state) &&
				orders.value.every(el => el.ordersDeliveries[0].type === orders.value[0].ordersDeliveries[0].type)
		)

		function validateAddBox() {
			const nums = getOrderIdAndBoxId(modelInput.value)

			if (!nums) return ElMessage.error('Неверное значение инпута')

			const orderId: number = nums[0]
			const boxId: number = nums[1]

			if (props.propOrderId && props.propOrderId !== orderId)
				return ElMessage.error('Этот заказ не может быть добавлен так как предустановлен заказ ' + props.propOrderId)

			setBoxIdByOrderId(orderId, boxId)

			getOrder(orderId)
		}

		async function getOrder(orderId: number) {
			isFetchingGetOrder.value = true

			if (orders.value.some(el => Number(el.id) === orderId)) {
				isFetchingGetOrder.value = false
				modelInput.value = ''
				return
			}

			const res = await apiGetOrderById(orderId)

			isFetchingGetOrder.value = false
			modelInput.value = ''

			if (res?.data) orders.value.push(res.data)
			else {
				delete mapOrderBoxes.value[orderId]
				ElMessage.error('Заказ не найден либо не готов транспортировке')
			}
		}

		function setBoxIdByOrderId(orderId: number, boxId: number) {
			if (mapOrderBoxes.value[orderId]) {
				if (!mapOrderBoxes.value[orderId].includes(boxId)) mapOrderBoxes.value[orderId].push(boxId)
			} else mapOrderBoxes.value[orderId] = [boxId]
		}

		async function apiGetOrderById(id: number) {
			try {
				return await axios.get<IMovingBoxesOrder>('/order/get-moving-boxes-order/' + id)
			} catch (e) {
				ElMessage.error('Не удалось загрузить заказ')
			}
		}

		function getOrderIdAndBoxId(val: string | null): undefined | [number, number] {
			if (!val) return undefined

			let orderId: undefined | string | number, boxId: undefined | string | number
			;[orderId, boxId] = val.split('-')

			orderId = parseInt(orderId)
			boxId = parseInt(boxId)

			if (Number.isInteger(orderId) && Number.isInteger(boxId)) return [orderId, boxId]
			else return undefined
		}

		function getLocalizeDeliveryType(type: enumDeliveryTypes) {
			const key = enumDeliveryTypes[type] as keyof typeof enumDeliveryTypes
			return localizeDeliveryTypes[key]
		}

		function removeOrder(id: string) {
			delete mapOrderBoxes.value[id]

			const idx = orders.value.findIndex(el => el.id === id)

			orders.value.splice(idx, 1)
		}

		async function send() {
			isFetchingSend.value = true

			const listOrderIds = orders.value.map(el => Number(el.id))

			try {
				await apiSend(listOrderIds)

				ctx.emit('success')

				ElMessage.info('Коробки успешно перемещены')
			} catch (e) {
				ElMessage.error('Произошла ошибка')
			}

			isFetchingSend.value = false
		}

		function apiSend(data: number[]) {
			return axios.post('/order/moving-order-boxes', { orders: data })
		}

		return {
			modelInput,
			orderWithBoxes,
			isConfirmMoving,
			isFetchingGetOrder,
			isFetchingSend,
			validateAddBox,
			getLocalizeDeliveryType,
			removeOrder,
			send
		}
	}
})
